// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) {
// 	p {
// 		font-size: 14pt;
// 	}
// }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) {
// 	p {
// 		font-size: 14pt;
// 	}
// }

// Large devices (desktops, 992px and up)
// @media (min-width: 992px) {
// 	p {
// 		font-size: 16pt;
// 	}
// }

// X-Large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) {
// 	p {
// 		font-size: 20pt;
// 	}
// }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
	h1 { font-size: 32pt; }
	h2 { font-size: 30pt; }
	h3 { font-size: 28pt; }
	h4 { font-size: 26pt; }
	h5 { font-size: 24pt; }
	h6 { font-size: 22pt; }
	p { font-size: 20pt; }
}

@media (min-width: 1700px) {
	h1 { font-size: 36pt; }
	h2 { font-size: 34pt; }
	h3 { font-size: 32pt; }
	h4 { font-size: 30pt; }
	h5 { font-size: 28pt; }
	h6 { font-size: 26pt; }
	p { font-size: 24pt; }
}

@media (min-width: 2400px) {
	h1 { font-size: 36pt; }
	h2 { font-size: 34pt; }
	h3 { font-size: 32pt; }
	h4 { font-size: 30pt; }
	h5 { font-size: 28pt; }
	h6 { font-size: 26pt; }
	p { font-size: 24pt; }
}

@media (min-width: 3000px) {
	h1 { font-size: 40pt; }
	h2 { font-size: 38pt; }
	h3 { font-size: 36pt; }
	h4 { font-size: 34pt; }
	h5 { font-size: 32pt; }
	h6 { font-size: 30pt; }
	p { font-size: 28pt; }
}