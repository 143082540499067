$blur: 5px;

.bg-particles-blur {
	-webkit-filter: blur($blur);
	-moz-filter: blur($blur);
	-o-filter: blur($blur);
	-ms-filter: blur($blur);
	filter: blur($blur);
}

@media (min-width: 1400px) {
	$blur: 7px;

	.bg-particles-blur {
		-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
		-o-filter: blur($blur);
		-ms-filter: blur($blur);
		filter: blur($blur);
	}
}

@media (min-width: 1700px) {
	$blur: 9px;

	.bg-particles-blur {
		-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
		-o-filter: blur($blur);
		-ms-filter: blur($blur);
		filter: blur($blur);
	}
}

@media (min-width: 2400px) {
	$blur: 11px;

	.bg-particles-blur {
		-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
		-o-filter: blur($blur);
		-ms-filter: blur($blur);
		filter: blur($blur);
	}
}

@media (min-width: 3000px) {
	$blur: 13px;

	.bg-particles-blur {
		-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
		-o-filter: blur($blur);
		-ms-filter: blur($blur);
		filter: blur($blur);
	}
}