@import 'background/bg_blur';
@import 'background/particle_blur';

// Page full size
body, html {
	height: 100%;
}

// Background image
.bg-image {
	position: fixed;
	z-index: -1;
	background-image: url('../img/background-5.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	transform: scale(1.1);
	width: 100%;
	height: 100%;
}

// Background particles
.bg-particles {
	position: fixed;
	z-index: -1;
	width: 100%;
	height: 100%;
}

// Default text color
p, h1, h2, h3, h4, h5, h6 {
	color: white;
}

.d-flex > div {
	margin: 10px;
}

// Unvisited link
a:link {
	color: rgb(226, 226, 226);
}

// Visited link
a:visited {
	color: rgb(226, 226, 226);
}

// Mouse over link
a:hover {
	color: rgb(192, 192, 192);
}

// Selected link
a:active {
	color: rgb(79, 218, 223);
}