$blur: 10px;

.bg-blur {
	-webkit-filter: blur($blur);
	-moz-filter: blur($blur);
	-o-filter: blur($blur);
	-ms-filter: blur($blur);
	filter: blur($blur);
}

@media (min-width: 1400px) {
	$blur: 12px;

	.bg-blur {
		-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
		-o-filter: blur($blur);
		-ms-filter: blur($blur);
		filter: blur($blur);
	}
}

@media (min-width: 1700px) {
	$blur: 14px;

	.bg-blur {
		-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
		-o-filter: blur($blur);
		-ms-filter: blur($blur);
		filter: blur($blur);
	}
}

@media (min-width: 2400px) {
	$blur: 16px;

	.bg-blur {
		-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
		-o-filter: blur($blur);
		-ms-filter: blur($blur);
		filter: blur($blur);
	}
}

@media (min-width: 3000px) {
	$blur: 18px;

	.bg-blur {
		-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
		-o-filter: blur($blur);
		-ms-filter: blur($blur);
		filter: blur($blur);
	}
}